import styles from './CheckIn.module.css';
import { useEffect, useRef } from 'react';
import { createWidget } from '@typeform/embed';


const CheckIn = () => {
    const typeformRef = useRef(null);

    useEffect(() => {

        const patientId = new URLSearchParams(window.location.search).get('p');

        const logEvent = async (patientId) => {
            const tb2ApiUrl = process.env.REACT_APP_TB2_API_URL;            
            await fetch(tb2ApiUrl + '/events/checkin', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    patientId: patientId
                })
            })
            .then(response => response.json())
            .then(data => {
                console.log(data); 
            })
            .catch(error => {
                console.error('Error in checkin:', error);
            });
        }
        
        createWidget('CHxB8zPz', {
            container: typeformRef.current,
            width: '100%',
            height: '100%',
            hidden: {
                pid: patientId
            },
            onSubmit: function() {
            }
        });

        logEvent(patientId);
        
    }, []);
    
    return (
        <div className={styles.checkin_form} ref={typeformRef}></div>
    )
}

export default CheckIn;