import React, { useContext } from 'react';
import Header from '../.shared/header';
import Footer from '../.shared/footer';
import Steps from './Steps';
import Support from './Support';
import SuccessStories from './SuccessStories';
import VialHero from './VialHero';
import MyStartHero from './MyStartHero';
import WomanHero from './WomanHero';
// import QuestionHero from './QuestionHero';
import StartMyJourney from './StartMyJourney';
import { SourceContext } from '../../utils/sourceContext';
    

function Landing() {
    const { isMyStart, isPMG } = useContext(SourceContext);

    return (
        <>
            <Header />
            {isMyStart ? <MyStartHero /> : isPMG ? <VialHero /> : <WomanHero />}
            <Steps />
            <Support />
            <StartMyJourney />
            <SuccessStories />
            <Footer />
        </>
    );
}

export default Landing;
