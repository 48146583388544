import React, { useRef, useState, useEffect } from 'react';
import Pricing from '../.shared/Pricing';
import Header from '../.shared/header';
import Footer from '../.shared/footer';

import styles from './Programs.module.css';
import boderline from "../../assets/boderline.png"
import Semaglutide from "../../assets/Semaglutide.png"
import Tirzepatide from "../../assets/Tirzepatide.png"
import or from "../../assets/or.png"
import or1 from "../../assets/or-1.png"
import tick from "../../assets/tick350.png"
import footerline from '../../assets/boderline.png';
import circleRight from '../../assets/circle-right.svg';


const Programs = () => {
        
    const pricing = Pricing();
    const prices = pricing.getPrices();
    const isStart = window.location.pathname.includes('start');
    const [isVisible, setIsVisible] = useState(false);
    const elementRef = useRef(null);

    function handleStart() {
        window.location.href = '/checkout';
    }

    function handleProgram(e) {
        let selection = pricing.getPlan(e.currentTarget.dataset.id)
        localStorage.setItem('price_id', selection.price_id);
        window.location.href = '/checkout';
    }

    function handleSemaHover(e) {
        e.target.classList.add('animate__animated', 'animate__pulse', styles.glowGreen);
    }
    function handleSemaHoverEnd(e) {
        e.target.classList.remove('animate__animated', 'animate__pulse', styles.glowGreen);
    }
    function handleTirzHover(e) {
        e.target.classList.add('animate__animated', 'animate__pulse', styles.glowBlue);
    }
    function handleTirzHoverEnd(e) {
        e.target.classList.remove('animate__animated', 'animate__pulse', styles.glowBlue);
    }
    function handleJourneyHover(e) {
        e.target.classList.add('animate__animated', 'animate__pulse', styles.glowGreen);
    }
    function handleJourneyHoverEnd(e) {
        e.target.classList.remove('animate__animated', 'animate__pulse', styles.glowGreen);
    }

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            {
                threshold: 0.5,
            }
        );

        let currentElement = elementRef.current;
        if (currentElement) {
            observer.observe(currentElement);
        }
        return () => {
            if (currentElement) {
                observer.unobserve(currentElement);
            }
        };
    }, []);

    return (
        <>
            <Header />
            <div className={styles.programBanner}>
                <div className={styles.bannerText}>
                    {!isStart &&
                        <div className={styles.bannerSubtitle}>Safe, Effective, Proven Weight Loss</div>
                    }                    
                    <div className={styles.bannerTitle}>Weight Loss Programs</div>
                    {isStart &&
                        <div className={styles.checkoutSelect}>First select your program</div>
                    }
                </div>
                <img className={styles.programDivider} src={boderline} alt='' />
            </div>

            <div className={styles.goToMaintenance}>
                <span>
                    These programs are designed for patients new to GLP-1s who are seeking treatment for weight loss. 
                    If you already have experience with GLP-1s and are looking to keep the weight off, consider 
                    our <a href="/maintenance">Maintenance Programs</a> instead.
                </span>
            </div>
            
            <div className={styles.programs}>

                {prices.semaglutide && 
                    <div id='Semaglutide' className={styles.program + (isVisible ? ' animate__animated animate__slideInLeft' : '')}>
                        <div className={styles.programPanel}>
                            <img className={styles.programImage} src={Semaglutide} alt='' />                                
                            <div className={styles.programText}>
                                <div className={styles.semaHeading}>
                                    <div className={styles.programTitle}>Semaglutide</div>
                                    <div className={styles.programSubtext}>Same active pharmaceutical ingredient in Ozempic® and Wegovy®</div>
                                </div>
                                <div className={styles.programDescription}>
                                    <span> Semaglutide is a GLP-1 receptor agonist that is injected once a week.</span>  New patients typically start with a lower dose and increase over time.
                                    Your plan will determine the maximum dose you can receive.**
                                </div>                                    
                            </div>
                        </div>
                            
                        <div className={styles.programPlans}>
                            {prices.semaglutide.starter && 
                                <div className={styles.programPlan}>
                                    <div className={styles.semaPlan}>Starter</div>
                                    <div className={styles.semaDescription}>Up to {prices.semaglutide.starter.fourWeeks?.dose ?? prices.semaglutide.starter.threeMonths.dose}</div>
                                    <div className={styles.semaglutidePlans}>
                                        {prices.semaglutide.starter.monthly &&
                                            <div className={styles.planSubscription} data-id={prices.semaglutide.starter.monthly.price_id} onClick={handleProgram}>
                                                <div className={styles.planPrice}>${prices.semaglutide.starter.monthly.total}<span className={styles.planPriceMo}>/mo</span></div>
                                                <div className={styles.letsGo + ' ' + styles.semaBackground} onMouseEnter={handleSemaHover} onMouseLeave={handleSemaHoverEnd}>Let's Go!<img className={styles.buttonArrow} src={circleRight} alt='Start' /></div>
                                            </div>
                                        }
                                        {prices.semaglutide.starter.monthly &&
                                            <img className={styles.orImg} src={or} alt='' />
                                        }
                                        {prices.semaglutide.starter.threeMonths &&
                                            <div className={styles.planSubscription} data-id={prices.semaglutide.starter.threeMonths.price_id} onClick={handleProgram}>
                                                <div className={styles.planPrice}>${prices.semaglutide.starter.threeMonths.startingAt}<span className={styles.planPriceMo}>/mo</span></div>
                                                <div className={styles.planName}>for 3 Months</div>
                                                <div className={styles.priceSubtext}> (${prices.semaglutide.starter.threeMonths.total} total)</div>
                                                <div className={styles.letsGo + ' ' + styles.semaBackground} onMouseEnter={handleSemaHover} onMouseLeave={handleSemaHoverEnd}>Let's Go!<img className={styles.buttonArrow} src={circleRight} alt='Start' /></div>
                                            </div>
                                        }
                                        {prices.semaglutide.starter.fourWeeks &&
                                            <div className={styles.planSubscription} data-id={prices.semaglutide.starter.fourWeeks.price_id} onClick={handleProgram}>
                                                <div className={styles.planPrice}>${prices.semaglutide.starter.fourWeeks.total}</div>
                                                <div className={styles.planName}>every 4 weeks<br/><sup>(8 week minimum)</sup></div>
                                                <div className={styles.letsGo + ' ' + styles.semaBackground} onMouseEnter={handleSemaHover} onMouseLeave={handleSemaHoverEnd}>Let's Go!<img className={styles.buttonArrow} src={circleRight} alt='Start' /></div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }

                            {prices.semaglutide.premium &&
                                <div className={styles.programPlan}>
                                    <div className={styles.semaPlan}>Premium</div>
                                    <div className={styles.semaDescription}>Up to {prices.semaglutide.premium.fourWeeks?.dose ?? prices.semaglutide.premium.threeMonths.dose}*</div>
                                    <div className={styles.semaglutidePlans}>
                                        {prices.semaglutide.premium.monthly &&
                                            <div className={styles.planSubscription} data-id={prices.semaglutide.premium.monthly.price_id} onClick={handleProgram}>
                                                <div className={styles.planPrice}>${prices.semaglutide.premium.monthly.total}<span className={styles.planPriceMo}>/mo</span></div>
                                                <div className={styles.letsGo + ' ' + styles.semaBackground} onMouseEnter={handleSemaHover} onMouseLeave={handleSemaHoverEnd}>Let's Go!<img className={styles.buttonArrow} src={circleRight} alt='Start' /></div>
                                            </div>
                                        }
                                        {prices.semaglutide.premium.monthly &&
                                            <img className={styles.orImg} src={or} alt='' />
                                        }
                                        {prices.semaglutide.premium.threeMonths &&
                                            <div className={styles.planSubscription} data-id={prices.semaglutide.premium.threeMonths.price_id} onClick={handleProgram}>
                                                <div className={styles.planPrice}>${prices.semaglutide.premium.threeMonths.startingAt}<span className={styles.planPriceMo}>/mo</span></div>
                                                <div className={styles.planName}>for 3 Months</div>
                                                <div className={styles.priceSubtext}> (${prices.semaglutide.premium.threeMonths.total} total)</div>
                                                <div className={styles.letsGo + ' ' + styles.semaBackground} onMouseEnter={handleSemaHover} onMouseLeave={handleSemaHoverEnd}>Let's Go!<img className={styles.buttonArrow} src={circleRight} alt='Start' /></div>
                                            </div>
                                        }
                                        {prices.semaglutide.premium.fourWeeks &&
                                            <div className={styles.planSubscription} data-id={prices.semaglutide.premium.fourWeeks.price_id} onClick={handleProgram}>
                                                <div className={styles.planPrice}>${prices.semaglutide.premium.fourWeeks.startingAt}</div>
                                                <div className={styles.planName}>every 4 weeks<br/><sup>(8 week minimum)</sup></div>
                                                <div className={styles.letsGo + ' ' + styles.semaBackground} onMouseEnter={handleSemaHover} onMouseLeave={handleSemaHoverEnd}>Let's Go!<img className={styles.buttonArrow} src={circleRight} alt='Start' /></div>
                                            </div>
                                        }                                            
                                    </div>
                                </div>
                            }
                        </div>
                        
                    </div>
                }


                {prices.tirzepatide &&
                    <div id='Tirzepatide' className={styles.program + (isVisible ? ' animate__animated animate__slideInRight' : '')}>
                            <div className={styles.programPanel}>
                                <img className={styles.programImage} src={Tirzepatide} alt='' />
                                <div className={styles.programText}>
                                    <div className={styles.tirzHeading}>
                                        <div className={styles.programTitle}>Tirzepatide</div>
                                        <div className={styles.programSubtext}>Same active pharmaceutical ingredient in Mounjaro® and Zepbound®</div>
                                    </div>
                                    <div className={styles.programDescription}>
                                        <span>Tirzepatide is both a GLP-1 and GIP receptor agonist that is injected once a week.</span>  New patients typically start with a lower dose and increase over time.
                                        Your plan will determine the maximum dose you can receive.**
                                    </div>
                                </div>

                            </div>

                            <div ref={elementRef} className={styles.programPlans}>
                                    {prices.tirzepatide.starter &&
                                        <div className={styles.programPlan}>
                                            <div className={styles.tirzPlan}>Starter</div>
                                            <div className={styles.tirzDescription}>Up to {prices.tirzepatide.starter.fourWeeks?.dose ?? prices.tirzepatide.starter.threeMonths.dose} per week</div>
                                            <div className={styles.tirzepatidePlans}>
                                                {prices.tirzepatide.starter.monthly &&
                                                    <div className={styles.planSubscription} data-id={prices.tirzepatide.starter.monthly.price_id} onClick={handleProgram}>
                                                        <div className={styles.planPrice}>${prices.tirzepatide.starter.monthly.total}<span className={styles.planPriceMo}>/mo</span></div>
                                                        <div className={styles.letsGo + ' ' + styles.tirzBackground} onMouseEnter={handleTirzHover} onMouseLeave={handleTirzHoverEnd}>Let's Go!<img className={styles.buttonArrow} src={circleRight} alt='Start' /></div>
                                                    </div>                                        
                                                }
                                                {prices.tirzepatide.starter.monthly &&
                                                    <img className={styles.orImg} src={or1} alt='' />
                                                }
                                                {prices.tirzepatide.starter.threeMonths &&
                                                    <div className={styles.planSubscription} data-id={prices.tirzepatide.starter.threeMonths.price_id} onClick={handleProgram}>
                                                        <div className={styles.planPrice}>${prices.tirzepatide.starter.threeMonths.startingAt}<span className={styles.planPriceMo}>/mo</span></div>
                                                        <div className={styles.planName}>for 3 Months</div>
                                                        <div className={styles.priceSubtext}> (${prices.tirzepatide.starter.threeMonths.total})</div>
                                                        <div className={styles.letsGo + ' ' + styles.tirzBackground} onMouseEnter={handleTirzHover} onMouseLeave={handleTirzHoverEnd}>Let's Go!<img className={styles.buttonArrow} src={circleRight} alt='Start' /></div>
                                                    </div>
                                                }
                                                {prices.tirzepatide.starter.fourWeeks &&
                                                    <div className={styles.planSubscription} data-id={prices.tirzepatide.starter.fourWeeks.price_id} onClick={handleProgram}>
                                                        <div className={styles.planPrice}>${prices.tirzepatide.starter.fourWeeks.startingAt}</div>
                                                        <div className={styles.planName}>every 4 weeks<br/><sup>(8 week minimum)</sup></div>
                                                        <div className={styles.letsGo + ' ' + styles.tirzBackground} onMouseEnter={handleTirzHover} onMouseLeave={handleTirzHoverEnd}>Let's Go!<img className={styles.buttonArrow} src={circleRight} alt='Start' /></div>
                                                    </div>
                                                }                                              
                                            </div>
                                        </div>
                                    }
                                    
                                    {prices.tirzepatide.premium &&
                                        <div className={styles.programPlan}>
                                            <div className={styles.tirzPlan}>Premium</div>
                                            <div className={styles.tirzDescription}>Up to {prices.tirzepatide.premium.fourWeeks?.dose ?? prices.tirzepatide.premium.threeMonths.dose} per week*</div>
                                            <div className={styles.tirzepatidePlans}>
                                                {prices.tirzepatide.premium.monthly &&
                                                    <div className={styles.planSubscription} data-id={prices.tirzepatide.premium.monthly.price_id} onClick={handleProgram}>
                                                        <div className={styles.planPrice}>${prices.tirzepatide.premium.monthly.total}<span className={styles.planPriceMo}>/mo</span></div>
                                                    </div>
                                                }
                                                {prices.tirzepatide.premium.monthly &&
                                                    <img className={styles.orImg} src={or1} alt='' />
                                                }
                                                {prices.tirzepatide.premium.threeMonths &&
                                                    <div className={styles.planSubscription} data-id={prices.tirzepatide.premium.threeMonths.price_id} onClick={handleProgram}>
                                                        <div className={styles.planPrice}>${prices.tirzepatide.premium.threeMonths.startingAt}<span className={styles.planPriceMo}>/mo</span></div>
                                                        <div className={styles.planName}>for 3 Months</div>
                                                        <div className={styles.priceSubtext}> (${prices.tirzepatide.premium.threeMonths.total} total)</div>
                                                        <div className={styles.letsGo + ' ' + styles.tirzBackground} onMouseEnter={handleTirzHover} onMouseLeave={handleTirzHoverEnd}>Let's Go!<img className={styles.buttonArrow} src={circleRight} alt='Start' /></div>
                                                    </div>
                                                }
                                                {prices.tirzepatide.premium.fourWeeks &&
                                                    <div className={styles.planSubscription} data-id={prices.tirzepatide.premium.fourWeeks.price_id} onClick={handleProgram}>
                                                        <div className={styles.planPrice}>${prices.tirzepatide.premium.fourWeeks.startingAt}</div>
                                                        <div className={styles.planName}>every 4 weeks<br/><sup>(8 week minimum)</sup></div>
                                                        <div className={styles.letsGo + ' ' + styles.tirzBackground} onMouseEnter={handleTirzHover} onMouseLeave={handleTirzHoverEnd}>Let's Go!<img className={styles.buttonArrow} src={circleRight} alt='Start' /></div>
                                                    </div>
                                                }                                               
                                            </div>
                                        </div>
                                    }
                                </div>

                    </div>
                }
            </div>
            <div className={styles.higherDoses}>*Contact us at <a href="mailto:social@tb2.health">social@tb2.health</a> for pricing on higher doses</div>
            <img className={styles.programBorder} src={footerline} alt='wait' />
            
            <div className={styles.allPrograms}>
                <div className={styles.programsTitle}><span>All</span> Programs include:</div>
                <div className={styles.programsDescription}>
                    <div className={styles.programsList}>
                        <div><img className={styles.checkmark} src={tick} alt='' />Initial consultation with a clinician</div>
                        <div><img className={styles.checkmark} src={tick} alt='' />Assignment of a Patient Care Coordinator</div>
                        <div><img className={styles.checkmark} src={tick} alt='' />Prescription for a GLP-1 agonist</div>
                        <div><img className={styles.checkmark} src={tick} alt='' />Followup with a clinician every 30 days</div>
                        <div><img className={styles.checkmark} src={tick} alt='' />Access to your clinician between consultations</div>
                        <div><img className={styles.checkmark} src={tick} alt='' />Free shipping to your door</div>
                        <div><img className={styles.checkmark} src={tick} alt='' />Plans renew automatically</div>
                        <div><img className={styles.checkmark} src={tick} alt='' />Cancel at any time</div>
                        <div><img className={styles.checkmark} src={tick} alt='' />Money back guarantee if you do not qualify for a prescription</div>
                    </div>
                </div>
                <div onMouseEnter={handleJourneyHover} onMouseLeave={handleJourneyHoverEnd} onClick={handleStart} className={styles.journeyBtn2}>Start My Weight Loss Journey<img className={styles.journeyArrow} src={circleRight} alt='' /> </div>
            </div>
            <Footer />
        </>

    );
};

export default Programs;