import React, { createContext, useState, useEffect } from 'react';
import Pricing from '../components/.shared/Pricing';

export const SourceContext = createContext();

export const SourceProvider = ({ children }) => {
    const [source, setSource] = useState(null);
    const [isMyStart, setIsMyStart] = useState(false);
    const [isPMG, setIsPMG] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    localStorage.setItem('todaysDate', today);

    const subdomain = window.location.host.split('.')[0];
    localStorage.setItem('subdomain', subdomain);

    let source = null;

    const pathParts = window.location.pathname.split('/');
    const path = pathParts.length > 1 ? pathParts[1].toLowerCase() : '';
    if (path === 'podcast') {
        localStorage.setItem('discount', path);
        source = 'podcast';
    } else if (
        path === 'ioa' ||
        path === 'axcethr' ||
        path === 'mma' ||
        path === 'palladium' ||
        path === 'osprey' ||
        path === 'fimco' ||
        path === 'axcet' ||
        path === 'origin' ||
        path === 'nfpcle' ||
        path === 'lake' ||
        path === 'den' ||
        path === 'empower' ||
        path === 'adha' ||
        path === 'briar' ||
        path === 'ufc' ||
        path === 'wiq' ||
        path === 'tko' ||
        path === 'tko2' ||
        path === 'ncot' ||
        path === 'bh1' ||
        path === 'bh2' ||
        path === 'bh3' ||
        path === 'cwm' ||
        path === 'glrcog' ||
        path === 'pasc' ||
        path === 'benefithub' ||
        path === 'tnemec' ||
        path === 'goodwill'
    ) {
        source = path;
        setIsMyStart(true);
    } else if (path === 'grace' || path === 'aaliyah' || path === 'ekleinman') {
        source = path;
    } else if (path === 'pmg' || path === 'pain') {
        source = path;
        setIsPMG(true);
    } else {
        source = subdomain;
    }

    const pricing = Pricing();
    const prices = pricing.getPrices(source);

    if (localStorage.getItem('source') === null) {
        localStorage.setItem('source', source);
    } else if (localStorage.getItem('source') !== source && prices.type === 'wholesale') {
        localStorage.setItem('source', source);
    }

    setSource(source);
    setLoading(false);
    }, []);

    if (loading) {
        return null;
    }

    return (
        <SourceContext.Provider value={{ source, isMyStart, isPMG }}>
            {children}
        </SourceContext.Provider>
    );
};