import styles from './Intake.module.css';
import { useEffect, useRef } from 'react';
import { createWidget } from '@typeform/embed';


const Intake = () => {
    const typeformRef = useRef(null);

    useEffect(() => {

        const logEvent = async (eventId) => {

            const tb2ApiUrl = process.env.REACT_APP_TB2_API_URL;            
            await fetch(tb2ApiUrl + '/events/intake', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    eventId: eventId
                })
            })
            .then(response => response.json())
            .then(data => {
                console.log(data); 
            })
            .catch(error => {
                console.error('Error in intake:', error);
            });
        }

        createWidget('qLFyKhZl', {
            container: typeformRef.current,
            width: '100%',
            height: '100%',
            onSubmit: function() {
            }
        });

        const eventId = new URLSearchParams(window.location.search).get('eid');
        if (eventId) {
            logEvent(eventId);
        }
        
    }, []);
    
    return (
        <div className={styles.intake_form} ref={typeformRef}></div>
    )
}

export default Intake;