import React, { useState, useEffect, useRef } from 'react';
import {  useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import Pricing from '../.shared/Pricing';
import PhoneNumber from './Phone';

import styles from './Checkout.module.css';
import lock from "../../assets/lock.png";
import semaglutide from "../../assets/Semaglutide.png";
import tirzepatide from "../../assets/Tirzepatide.png";
import sectionrule from "../../assets/Section-rule.png";
import sectionrulevertical from "../../assets/Section-rule-vertical.png";
import downarrowblack from "../../assets/arrow.png";
import lockWhite from '../../assets/lock-white.png';

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const pricing = Pricing();
  const prices = pricing.getPrices();

  const defaultPlan = (Object.values(Object.values(pricing.getPrices())[0])[0]).fourWeeks;
  
  const priorId = localStorage.getItem('price_id');
  const priorPlan = pricing.getPlan(priorId);
  const selectedPlan = priorPlan ?? defaultPlan;
  localStorage.setItem('price_id', selectedPlan.price_id);

  const [plan, setPlan] = useState(selectedPlan);
  const [total, setTotal] = useState();
  const [nextPaymentDue, setNextPaymentDue] = useState('');
  
  const [phoneErrorMessage, setPhoneErrorMessage] = useState(null);
  const [emailErrorMessage, setEmailErrorMessage] = useState(null);
  const [cardErrorMessage, setCardErrorMessage] = useState(null);
  const [addressErrorMessage, setAddressErrorMessage] = useState(null);
  const [coupon, setCoupon] = useState(null);


  const [sendPayment, setSendPayment] = useState(false);
  const [formData, setFormData] = useState({ email: '', name: '', addressLine1: '', addressLine2: '', city: '', state: '', zipCode: '' });
  const [showOptions, setShowOptions] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const emailRef = useRef();
  const planRef = useRef();
  const subscribeRef = useRef();

  const handleSelectPlan = (event) => {
    const selectedPlan = pricing.getPlan(event.target.value);
    setPlan(selectedPlan);
    setTotal(!selectedPlan.total.includes('.') ? selectedPlan.total + '.00' : selectedPlan.total);
    localStorage.setItem('price_id', selectedPlan.price_id);
  };

  useEffect(() => {
    setTotal(!plan.total.includes('.') ? plan.total + '.00' : plan.total);  
    const nextDue = new Date();
    if (plan.name.includes('4')) nextDue.setDate(nextDue.getDate() + 28);
    else if (plan.name.includes('3')) nextDue.setMonth(nextDue.getMonth() + 3);
    else if (plan.name.includes('6')) nextDue.setMonth(nextDue.getMonth() + 6);
    else nextDue.setMonth(nextDue.getMonth() + 1);
    setNextPaymentDue(nextDue.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }));
  }, [plan]);

  useEffect(() => {
    if (formData.name === 'DavidM Adtaxi') {
      const testPlan = pricing.getTestPlan();
      setPlan(testPlan);
      setTotal(!testPlan.total.includes('.') ? testPlan.total + '.00' : testPlan.total);
      localStorage.setItem('price_id', testPlan.price_id);
    }
  }, [formData.name, pricing])

  useEffect(() => {
    const planNode = planRef.current;
    planNode.classList.add('animate__animated', 'animate__lightSpeedInLeft');
    
    const handleAnimationEnd = () => {
      planNode.classList.remove('animate__animated', 'animate__lightSpeedInLeft');
    };

    planNode.addEventListener('animationend', handleAnimationEnd);
    
    return () => {
      planNode.classList.remove('animate__animated', 'animate__lightSpeedInLeft');
    };
  }, [total]);

  useEffect(() => {
    if (localStorage.discount === 'podcast') {
      const coupon = prices.coupons.find(coupon => coupon.name === 'Podcast');
      if (coupon) {
        setCoupon(coupon);
      }
    }
  }, [prices.coupons]);



  const handleSelectClick = (event) => {
    event.preventDefault();
    setShowOptions(!showOptions);
  };

  const handleOptionClick = (event) => {
    setFormData({ ...formData, 
      state: event.target.getAttribute('val'), 
      stateDisplay: event.target.innerText });
    setShowOptions(false);
  };

  const handleFieldChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleEmailChange = (event) => {
    const { name, value } = event.target;
    let email = value;
    setFormData((prevFormData) => ({ ...prevFormData, email: email }));

    // eslint-disable-next-line no-useless-escape
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(email)) {
      setEmailErrorMessage(null);
      setValidationErrors(errors => {
        const {[name]: _, ...rest} = errors;
        return rest;
      })
    }
    else {
      setEmailErrorMessage('Invalid email');
      setValidationErrors(errors => ({ ...errors, [name]: 'Please enter a valid email' }));
    }
  };

  const handleZipChange = (event) => {
    const { name, value } = event.target;
    let zipCode = value;
    setFormData((prevFormData) => ({ ...prevFormData, zipCode: zipCode }));

    if (zipCode.length !== 5) {
      setAddressErrorMessage('Zip code must be exactly 5 digits');
      setValidationErrors(errors => ({ ...errors, [name]: 'Please enter a valid 5-digit zip code' }));
    }
    else {
      setAddressErrorMessage(null);
      setValidationErrors(errors => {
        const {[name]: _, ...rest} = errors;
        return rest;
      })
    }
  };

  const handleSubscribeHover = (event) => {
    event.target.classList.add('animate__animated', 'animate__pulse', styles.glow);
  };
  const handleSubscribeHoverEnd = (event) => {
    event.target.classList.remove('animate__animated', 'animate__pulse', styles.glow);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;
    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    if (!formData.email) { setEmailErrorMessage('Email address is required'); emailRef.current.focus(); return; }
    else { setEmailErrorMessage(null) }

    if (!formData.cell) { setPhoneErrorMessage('Phone number is required'); document.getElementById('phone-section').focus(); return; }
    else { setPhoneErrorMessage(null) }

    if (!formData.name) { setCardErrorMessage('Please enter the name that appears on the card'); document.getElementById('card-section').focus(); return; }
    else { setCardErrorMessage(null) }

    if (!formData.addressLine1 || !formData.city || !formData.state || !formData.zipCode) {
      setAddressErrorMessage('Address Line 1, City, State, and Zip are required');
      return;
    }
    else { setAddressErrorMessage(null) };
        
    setSendPayment(true);
    
    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: {
          name: formData.name,
          email: formData.email,
          address: {
            line1: formData.addressLine1,
            city: formData.city,
            state: formData.state,
            postal_code: formData.zipCode
          }
        }
      });

      if (error) {
        setCardErrorMessage(error.message);
        console.log(error.message)
      } else {
        await fetch(process.env.REACT_APP_TB2_API_URL + '/events/create-subscription', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            paymentMethodId: paymentMethod.id,
            email: formData.email,
            name: formData.name,
            address1: formData.addressLine1,
            address2: formData.addressLine2,
            city: formData.city,
            state: formData.state,
            zipCode: formData.zipCode,
            phone: formData.cell,
            priceId: plan.price_id,
            plan: plan.title,
            source: localStorage.getItem('source'),
            subdomain: localStorage.getItem('subdomain'),
            coupon: coupon && coupon.id
          })
        })
        .then(response => response.json())
        .then(data => {
          console.log(data); 
          if (data.status !== 'active') {
            setCardErrorMessage('An error occurred while processing your payment.');
          }
          else {
            window.location.href = '/intake?eid=' + data.eventId;
          }
        })
        .catch(error => {
          console.error('Error creating subscription:', error);
          setCardErrorMessage('An error occurred while processing your payment.');
        });
      }
    } catch (error) {
      console.error('Error:', error);
      setCardErrorMessage('An error occurred while processing your payment.');
    } finally {
      setSendPayment(false);
    }
  };

  const cardPlaceholderSize = window.innerWidth < 513 ? '16px' : '26px';
  const cardElementOptions = {
    style: {
      base: {
        fontSize: cardPlaceholderSize,
        fontWeight: 'bold',
        color: '#32325d',
        '::placeholder': {
          fontSize: cardPlaceholderSize,
        },
      },
      invalid: {
        color: '#fa755a',
      },
    },
    hidePostalCode: true,
  };


  return (
    <div className={styles.checkout}>

      <div className={styles.leftSection}>
        <div ref={planRef} className={styles.planBox + ' ' + (selectedPlan.title.includes('Tirzepatide') ? styles.tirzBox : styles.semaBox)}>
          <div className={styles.title + ' ' + (selectedPlan.title.includes('Tirzepatide') ? styles.tirzBackground : styles.semaBackground)}>
            <img className={styles.titleImage + ' ' + (selectedPlan.title.includes('Tirzepatide') ? styles.tirzImg : styles.semaImg)} src={plan.name.includes('Tirzepatide') ? tirzepatide : semaglutide} alt='' />
            <div className={styles.titleName}>
              <div className={styles.titlePlan}><p>Subscribe to</p>{plan.title}</div>
              <div className={styles.titleDose}>Up to {plan.dose}</div>
            </div>
          </div>
          {/* <img className={styles.horizontalRule} src={sectionrule} alt='' /> */}
          <div className={styles.planContainer}>
            <div className={styles.planPrice}>
              <div><span className={styles.dollar}>$</span>{total}</div> 
              <div className={styles.planPeriod}>{plan.name.includes('3') ? 'Every 3 months' : plan.name.includes('4') ? 'Every 4 weeks' : plan.name.includes('6') ? 'Every 6 months' : 'Monthly'}</div>
            </div>
            <img className={styles.verticalRule} src={sectionrulevertical} alt='' />
            <div className={styles.planShipping}>
              <div className={styles.planFree}>FREE</div>
              <div>Shipping</div>
            </div>
          </div>
          <img className={styles.horizontalRule} src={sectionrule} alt='' />
          <div className={styles.planSubtext}>
            {plan.name.includes('6') && '6 month supply. Program renews every 6 months. Cancel at any time.'}
            {plan.name.includes('4') && '8 week minimum. Program renews every 8 weeks. Cancel at any time.'}
            {plan.name.includes('3') && '3 month supply. Billed every 3 months. Cancel at any time.'}
            {plan.name.includes('1') && '1 month supply. Billed every month. Cancel at any time.'}
          </div>
        </div>
        <div className={styles.selectButton}>
          <select id='selectPlan' title='Select Plan' value='' onChange={handleSelectPlan}>
            <option value='' disabled hidden>Select A Different Program</option>
            {
              Object.values(pricing.getPrices()).map((med) => {
                return Object.values(med).map((level) => {
                  return Object.values(level).map((period) => {
                    return period.price_id ? <option key={period.price_id} value={period.price_id}>{period.name} - ${period.total}</option> : ''
                  });
                });
              })
            }
          </select>
          <img src={downarrowblack} className={styles.differentArrow} alt=''/>
        </div>
      </div>

      <div className={styles.rightSection}>
        <div className={styles.paymentDetails}>
          <div className={styles.secureDetails}>Secure Payment Details</div>
          <div className={styles.securePayment}>
            <p><img style={{ width: "17px" }} src={lock} alt='' /> Encrypted</p>
            <p>|</p>
            <p>Powered By Stripe</p>
          </div>
          <div className={styles.fieldSection}>
            <div className={styles.emailContainer}>
              <div>Email:</div>
              <input type='email' name='email' value={formData.email} ref={emailRef} label='Email:' placeholder='abc@123.com' title='Email' onChange={handleEmailChange} />
            </div>
            { emailErrorMessage && <div className={styles.fieldError}>{emailErrorMessage}</div>}
          </div>
          <div id="phone-section" className={styles.fieldSection} tabIndex='-1'>
            <PhoneNumber formData={formData} setFormData={setFormData} setValidationErrors={setValidationErrors} />
            {phoneErrorMessage && <div className={styles.fieldError}>{phoneErrorMessage}</div>}
          </div>
          <div id='card-section' className={styles.fieldSection} tabIndex='-1'>
            <div className={styles.cardTitle}>Card Information</div>
            <div className={styles.cardBox}>
              <div className={styles.field}>
                <CardElement options={cardElementOptions} />
              </div>        
              <img className={styles.fieldRule} src={sectionrule} alt='' />
              <input name='name' className={styles.field} type='text' value={formData.name} placeholder='Full Name On Card' onChange={handleFieldChange} />
            </div>
            { cardErrorMessage && <div className={styles.fieldError}>{cardErrorMessage}</div>}
          </div>
          <div className={styles.fieldSection}>
            <div className={styles.cardTitle}>Billing Address</div>
            <div className={styles.cardBox}>
              <input name='addressLine1' className={styles.field} type='text' placeholder='Address Line 1' value={formData.addressLine1} required={true} onChange={handleFieldChange} />
              <img className={styles.fieldRule} src={sectionrule} alt='' />
              <input name='addressLine2' className={styles.field} type='text' placeholder='Address Line 2' value={formData.addressLine2} onChange={handleFieldChange} />
              <img className={styles.fieldRule} src={sectionrule} alt='' />
              <div className={styles.billingAddress}>
                <input name='city' className={styles.city} type='text' placeholder='City' value={formData.city} required={true} onChange={handleFieldChange} />
                <img className={styles.verticalRule} src={sectionrulevertical} alt='' />
                <input name='zipcode' className={styles.city} type='number' maxLength='5' placeholder='Zip' value={formData.zipCode} required={true} onChange={handleZipChange} />
              </div>
              <img className={styles.fieldRule} src={sectionrule} alt='' />
              <div className={styles.field} required={true} onClick={handleSelectClick}>
                {formData.state && 
                  <div className={styles.stateField}><center>{formData.stateDisplay}</center></div>
                }
                {!formData.state && 
                  <div className={styles.stateFieldPlaceholder}><center>State</center></div>
                }
                {showOptions && (
                  <div className={styles.statePopup}>
                    <div className={styles.stateOption} val='AL' onClick={handleOptionClick}>Alabama</div>
                    <div className={styles.stateOption} val='AK' onClick={handleOptionClick}>Alaska</div>
                    <div className={styles.stateOption} val='AZ' onClick={handleOptionClick}>Arizona</div>
                    <div className={styles.stateOption} val='AR' onClick={handleOptionClick}>Arkansas</div>
                    <div className={styles.stateOption} val='CA' onClick={handleOptionClick}>California</div>
                    <div className={styles.stateOption} val='CO' onClick={handleOptionClick}>Colorado</div>
                    <div className={styles.stateOption} val='CT' onClick={handleOptionClick}>Connecticut</div>
                    <div className={styles.stateOption} val='DE' onClick={handleOptionClick}>Delaware</div>
                    <div className={styles.stateOption} val='DC' onClick={handleOptionClick}>District of Columbia</div>
                    <div className={styles.stateOption} val='FL' onClick={handleOptionClick}>Florida</div>
                    <div className={styles.stateOption} val='GA' onClick={handleOptionClick}>Georgia</div>
                    <div className={styles.stateOption} val='HI' onClick={handleOptionClick}>Hawaii</div>
                    <div className={styles.stateOption} val='ID' onClick={handleOptionClick}>Idaho</div>
                    <div className={styles.stateOption} val='IL' onClick={handleOptionClick}>Illinois</div>
                    <div className={styles.stateOption} val='IN' onClick={handleOptionClick}>Indiana</div>
                    <div className={styles.stateOption} val='IA' onClick={handleOptionClick}>Iowa</div>
                    <div className={styles.stateOption} val='KS' onClick={handleOptionClick}>Kansas</div>
                    <div className={styles.stateOption} val='KY' onClick={handleOptionClick}>Kentucky</div>
                    <div className={styles.stateOption} val='LA' onClick={handleOptionClick}>Louisiana</div>
                    <div className={styles.stateOption} val='ME' onClick={handleOptionClick}>Maine</div>
                    <div className={styles.stateOption} val='MD' onClick={handleOptionClick}>Maryland</div>
                    <div className={styles.stateOption} val='MA' onClick={handleOptionClick}>Massachusetts</div>
                    <div className={styles.stateOption} val='MI' onClick={handleOptionClick}>Michigan</div>
                    <div className={styles.stateOption} val='MN' onClick={handleOptionClick}>Minnesota</div>
                    <div className={styles.stateOption} val='MS' onClick={handleOptionClick}>Mississippi</div>
                    <div className={styles.stateOption} val='MO' onClick={handleOptionClick}>Missouri</div>
                    <div className={styles.stateOption} val='MT' onClick={handleOptionClick}>Montana</div>
                    <div className={styles.stateOption} val='NE' onClick={handleOptionClick}>Nebraska</div>
                    <div className={styles.stateOption} val='NV' onClick={handleOptionClick}>Nevada</div>
                    <div className={styles.stateOption} val='NH' onClick={handleOptionClick}>New Hampshire</div>
                    <div className={styles.stateOption} val='NJ' onClick={handleOptionClick}>New Jersey</div>
                    <div className={styles.stateOption} val='NM' onClick={handleOptionClick}>New Mexico</div>
                    <div className={styles.stateOption} val='NY' onClick={handleOptionClick}>New York</div>
                    <div className={styles.stateOption} val='NC' onClick={handleOptionClick}>North Carolina</div>
                    <div className={styles.stateOption} val='ND' onClick={handleOptionClick}>North Dakota</div>
                    <div className={styles.stateOption} val='OH' onClick={handleOptionClick}>Ohio</div>
                    <div className={styles.stateOption} val='OK' onClick={handleOptionClick}>Oklahoma</div>
                    <div className={styles.stateOption} val='OR' onClick={handleOptionClick}>Oregon</div>
                    <div className={styles.stateOption} val='PA' onClick={handleOptionClick}>Pennsylvania</div>
                    <div className={styles.stateOption} val='RI' onClick={handleOptionClick}>Rhode Island</div>
                    <div className={styles.stateOption} val='SC' onClick={handleOptionClick}>South Carolina</div>
                    <div className={styles.stateOption} val='SD' onClick={handleOptionClick}>South Dakota</div>
                    <div className={styles.stateOption} val='TN' onClick={handleOptionClick}>Tennessee</div>
                    <div className={styles.stateOption} val='TX' onClick={handleOptionClick}>Texas</div>
                    <div className={styles.stateOption} val='UT' onClick={handleOptionClick}>Utah</div>
                    <div className={styles.stateOption} val='VT' onClick={handleOptionClick}>Vermont</div>
                    <div className={styles.stateOption} val='VA' onClick={handleOptionClick}>Virginia</div>
                    <div className={styles.stateOption} val='WA' onClick={handleOptionClick}>Washington</div>
                    <div className={styles.stateOption} val='WV' onClick={handleOptionClick}>West Virginia</div>
                    <div className={styles.stateOption} val='WI' onClick={handleOptionClick}>Wisconsin</div>
                    <div className={styles.stateOption} val='WY' onClick={handleOptionClick}>Wyoming</div>
                  </div>
                )}
              </div>
            </div>
            {addressErrorMessage && <div className={styles.fieldError}>{addressErrorMessage}</div>}
          </div>
          <div className={styles.lineItem}>
            <div>Shipping</div>
            <div className={styles.free}>FREE</div>
          </div>
          {coupon &&
            <div className={styles.lineItem}>
              <div>{coupon.name + ' ' + coupon.discount*100 + '% Discount'}</div>
              <div className={styles.total}>${(total*coupon.discount).toFixed(2)}</div>
            </div>
          }
          <div className={styles.lineItem}>
            <div>Total Due Today</div>
            <div id='checkout-price' className={styles.total}>${coupon === null ? total : (total*(1-coupon.discount)).toFixed(2)}</div>
          </div>
          <div className={styles.subscribeButtonBox}>
            <div ref={subscribeRef} className={styles.subscribeButton} 
              onMouseEnter={handleSubscribeHover} onMouseLeave={handleSubscribeHoverEnd} onClick={handleSubmit} type="button" disabled={!stripe || sendPayment}>
              {sendPayment ? 'Payment Processing ...' : 'Subscribe to ' + plan.title}
              <img src={lockWhite} alt='' />
            </div>
          </div>
          <div className={styles.paymentText}>
            {plan.name.includes('6') && 'Maintenance programs last 6 months and automatically renew. Your next payment of $' + total + ' will be charged on ' + nextPaymentDue + ' and recur every 6 months'}
            {plan.name.includes('4') && 'Each program is an 8 week subscription, automatically renews, and is paid in two installments. Your next payment of $' + total + ' will be charged on ' + nextPaymentDue + ' and recur every 4 weeks'}
            {plan.name.includes('3') && 'Your next payment of $' + total + ' will be charged on ' + nextPaymentDue + ' and recur every 3 months'}
            {plan.name.includes('1') && 'Your next payment of $' + total + ' will be charged on ' + nextPaymentDue + ' and recur every month'}
          </div>
          <div className={styles.paymentText}>
            All programs include the assigment of a clinician who will determine eligibility for the selected program, a patient care coordinator, access to a secure messaging portal, and weekly followups. A full refund is provided if you are determined not eligible for a prescription.
          </div>
          <div className={styles.paymentText}>
            By placing this order, you agree to our <a href='/terms' target='_blank'>terms of service</a> and our <a href='/privacy' target='_blank'>privacy policy</a>. By confirming your subscription, you allow tb2 to automatically charge you for future payments in accordance with their terms. Cancel at any time.
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutForm;
