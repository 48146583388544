import Pricing from '../components/.shared/Pricing';

export const handleRedirect = async () => {
    const pricing = Pricing();
    const source = localStorage.getItem('source') ?? 'nocookie';
    const prices = pricing.getPrices(source);
    const redirectUrl = prices.myStartClickUrl + source;

    try {
        const response = await fetch(process.env.REACT_APP_TB2_API_URL + '/events/redirect', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                source: localStorage.getItem('source'),
                subdomain: localStorage.getItem('subdomain'),
                redirectUrl: redirectUrl,
            }),
        });

        const data = await response.json();
        console.log(data);
    } catch (error) {
        console.error('Error capturing redirect:', error);
    } finally {
        window.location.href = redirectUrl;
    }
};
